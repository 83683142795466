import React /*, { useState } */ from "react";
import { graphql } from "gatsby";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { Seo } from "../base/Seo";

import Layout from "../components/Layout";
import Heading from "../components/Heading";
import Card from "../components/Card";

import "./browse.scss";

export default function Browse({ data }) {
  const posts = data.allMarkdownRemark.edges;
  //const [orderBy, setOrderBy] = useState("recent");

  return (
    <>
      <Seo title="Browse" description="Browse Description" />

      <Layout>
        <div className="section">
          <div className="page-header">
            <Heading tag="h1">
              Browse.{" "}
              <span className="paleTxt">
                Discover new and popular resources.
              </span>
            </Heading>
            <p>
              View all resources available. Sort and use filters to find what
              fits your needs
            </p>
          </div>
          {/*<div className="filters">
          <div className="inputGroup">
            Sort by
            <select name="sortBy" id="sort-by">
              <option value="recent">Most recent</option>
              <option value="popular">Most popular</option>
            </select>
          </div>
  </div>*/}
        </div>

        <div className="section">
          <div className="browse-archive">
            <div className="card-listing">
              {posts.map((post) => {
                return <Card key={post.node.id} postNode={post.node} />;
              })}
            </div>

            <div className="paginate">
              <span className="disabled">
                <FiChevronLeft />
              </span>
              <span className="active">1</span>
              <span>2</span>
              <span>
                <FiChevronRight />
              </span>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query BrowsePageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "single-post" } } }
      sort: { fields: frontmatter___publish_date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            featured_img
            category
            authors
          }
        }
      }
    }
  }
`;

/*
query BlogRollQuery {
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: { frontmatter: { template: { eq: "blog-post" } } }
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          template
          date(formatString: "MMMM DD, YYYY")
          featuredpost
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
*/
